import React, { useRef } from "react";
import classNames from "classnames";
import { InView } from "@base";
import { Player, Ui, Youtube, Vimeo } from "@vime/react";
import "@vime/core/themes/default.css";
import "@vime/core/themes/light.css";

const Video = React.memo(
  ({ preserveAspectRatio, url, showControls, autoplay }) => {
    const player = useRef();

    if (url) {
      let id;
      const provider = url.match("http(s)?://(www.)?youtube|youtu.be")
        ? "youtube"
        : "vimeo";
      if (provider === "youtube") {
        if (url.match("embed")) {
          [id] = url.split(/embed\//)[1].split('"');
        } else {
          [id] = url.split(/v\/|v=|youtu\.be\//)[1].split(/[?&]/);
        }
      }

      if (provider === "vimeo") {
        id = url.split("/").pop();
      }

      return (
        <InView
          onEnter={() => {
            if (autoplay && player.current) player.current.play();
          }}
          onExit={() => {
            if (player.current) player.current.pause();
          }}
          observerOptions={{
            threshold: 0.01,
          }}
          className={classNames("w-full", {
            "pointer-events-none": !showControls,
            "absolute inset-0 overflow-hidden": !preserveAspectRatio,
          })}
        >
          <Player
            ref={player}
            controls={showControls}
            autoplay={autoplay}
            muted={autoplay}
            loop={autoplay}
          >
            {provider === "youtube" && <Youtube videoId={id} />}
            {provider === "vimeo" && <Vimeo byline={false} videoId={id} />}
            <Ui>{/* UI components are placed here. */}</Ui>
          </Player>
        </InView>
      );
    }
    return null;
  }
);

Video.defaultProps = {
  loop: true,
  autoplay: true,
  showControls: false,
  preserveAspectRatio: true,
};

export default Video;
